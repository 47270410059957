import React from "react";

import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <>
      <SEO title="404: Not found" />
      <div className="text-center">404</div>
    </>
  );
}

export default NotFoundPage;
